var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"nachrichtenbalke"}},[_c('div',{staticClass:"nachricht-card",class:{ungelesen: _vm.neuigkeit.status === 'ungelesen'},on:{"click":function($event){return _vm.zuNachrichtDetails(_vm.neuigkeit)}}},[_c('div',{staticClass:"nachricht-card-body"},[_c('i',{staticClass:"fa-solid fa-circle nachricht-status",class:{'nachricht-status-ungelesen':
         (_vm.neuigkeit.status === 'ungelesen') && _vm.mandant === 1,
                 'nachricht-status-ungelesen-oesa':
                 (_vm.neuigkeit.status === 'ungelesen')
                 && _vm.mandant === 3,
                 'nachricht-status-ungelesen-oevo':
                 (_vm.neuigkeit.status === 'ungelesen')
                 && _vm.mandant === 5,
                  'nachricht-status-gelesen':
                  _vm.neuigkeit.status === 'gelesen'}}),(_vm.windowWidth > 576)?_c('div',{staticClass:"nachricht-vorschau"},[_c('div',{staticClass:"copy_12 nachricht-betreff",class:{'vgh-grau-color': _vm.mandant === 1,
             'oesa-gruen-sekundaer-color': _vm.mandant === 3,
             'oevo-grau-color': _vm.mandant === 5}},[_c('span',{staticClass:"betreff-desktop",domProps:{"innerHTML":_vm._s(_vm.neuigkeit.betreff)}}),_c('span',{staticClass:"datum-desktop"},[_vm._v(" "+_vm._s(_vm.erzeugeEmpfaenger(_vm.neuigkeit))+" | "+_vm._s(_vm.trimDatum(_vm.neuigkeit.erstellungsDatum))+" ")])]),_c('div',{staticClass:"copy_16 nachricht-textvorschau"},[_c('vue-markdown',[_vm._v(_vm._s(_vm.neuigkeit.text))])],1),_c('span',{class:{'fa-paperclip-gelesen' : _vm.neuigkeit.status !== 'ungelesen'}},[(_vm.neuigkeit.uploadList.length)?_c('i',{staticClass:"fa-solid fa-paperclip"}):_vm._e()])]):_c('div',{staticClass:"nachricht-vorschau"},[_c('div',{staticClass:"copy_12 nachricht-senderinfo-mobile",class:{'vgh-grau-color': _vm.mandant === 1,
             'oesa-gruen-sekundaer-color': _vm.mandant === 3,
             'oevo-grau-color': _vm.mandant === 5}},[_c('span',{staticClass:"empfaenger-mobil"},[_vm._v(_vm._s(_vm.erzeugeEmpfaenger(_vm.neuigkeit)))]),_c('span',{staticClass:"datum-mobil"},[_vm._v(_vm._s(_vm.trimDatum(_vm.neuigkeit.erstellungsDatum)))])]),_c('div',{staticClass:"copy_12 nachricht-betreff",class:{'vgh-grau-color': _vm.mandant === 1,
               'oesa-gruen-sekundaer-color': _vm.mandant === 3,
               'oevo-grau-color': _vm.mandant === 5}},[_c('span',{staticClass:"betreff-mobil",domProps:{"innerHTML":_vm._s(_vm.neuigkeit.betreff)}}),_c('span',{staticClass:"datum-mobil",class:{'fa-paperclip-gelesen' : _vm.neuigkeit.status !== 'ungelesen'}},[(_vm.neuigkeit.uploadList.length)?_c('i',{staticClass:"fa-solid fa-paperclip"}):_vm._e()])]),_c('div',{staticClass:"copy_16 nachricht-textvorschau"},[_c('vue-markdown',[_vm._v(_vm._s(_vm.neuigkeit.text))])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }